import React, { useState } from "react";
import axios from "axios";

const DivButton = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [showContactForm, setShowContactForm] = useState(false);
  // Handle form field changes
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values);
    axios
      .post("http://192.168.0.119:8082/harsh", values) // Update this to match backend endpoint
      .then((res) => {
        console.log("Form submitted successfully", res);
        alert("Form submitted successfully!");
        setValues({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setShowContactForm(false); // Close the form after successful submission
      })
      .catch((err) => {
        console.log("Error submitting form", err);
        alert("There was an error submitting the form");
      });
  };

  const closeForm = () => setShowContactForm(false);

  return (
    <div>
      <div onClick={() => setShowContactForm(true)}>
        <img
          src="WhatsApp_Image_2024-09-05_at_11.03.14_868cf8a1-removebg-preview.png"
          alt="phone"
          className="contact-img"
        />
      </div>

      {showContactForm && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "400px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Contact Us</h2>
            <h2 style={{ textAlign: "center" }}>TOLL FREE - 1800 891 2166</h2>
            <h2 style={{ textAlign: "center" }}>
              Support@villagersproduct.com
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone No:</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input type="submit" value="Send" />
              </div>
            </form>
            <button
              onClick={closeForm}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <style jsx>{`
        .form-group {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        .form-group label {
          width: 100px;
          margin-right: 10px;
          color: #555;
          font-weight: bold;
        }

        .form-group input,
        .form-group textarea {
          flex: 1;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-sizing: border-box;
        }

        .form-group textarea {
          resize: vertical;
          height: 100px;
        }

        .form-group input[type="submit"] {
          background-color: #3498db;
          color: white;
          border: none;
          cursor: pointer;
          font-size: 16px;
        }

        .form-group input[type="submit"]:hover {
          background-color: #2980b9;
        }
        .contact-img {
          width: 200px;
          height: 200px;
          transition: transform 0.3s ease, box-shadow 0.3s ease,
            filter 0.3s ease;
          padding-bottom: 0;
        }
        .contact-img:hover {
          transform: scale(1.1); /* Enlarges the image by 10% */
          filter: brightness(1.2); /* Lightens the image slightly */
        }
      `}</style>
    </div>
  );
};

export default DivButton;
