import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import "./style.css";
import Contact from "./Contact";
import React from "react";

export default function BasicGrid() {
  return (
    <Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {/* First Image */}
          <Grid
            size={{ xs: 12, sm: 12, md: 6, lg: 6 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              sx={{
                width: "350px",
                height: "250px",
                borderRadius: "10px",
                marginLeft: "-100px",
              }}
            >
              <a href="https://callanywhere.co.in/">
                <img
                  src="cloud5.png"
                  alt="logo"
                  style={{ width: "100%", height: "100%" }}
                  className="hover-image"
                />
              </a>
            </Grid>
          </Grid>

          {/* Second Image */}
          <Grid
            size={{ xs: 12, sm: 12, md: 6, lg: 6 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid sx={{ width: "250px", height: "250px" }}>
              <a href="https://quicksmart.ai">
                <img
                  src="Untitled design.png"
                  alt="logo"
                  style={{ width: "100%", height: "100%" }}
                  className="hover-image"
                />
              </a>
            </Grid>
          </Grid>
        </Grid>

        {/* Centered Image */}
        <Grid
          size={{ xs: 12, md: 6, lg: 6 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="https://callanywhere.co.in/">
            <Grid
              sx={{
                width: "300px",
                height: "150px",
                background: "white",
                borderRadius: "100px",
                marginTop: "-10px",
              }}
            >
              <img
                src="Untitled de2sign.png"
                alt="logo"
                style={{ width: "100%", height: "100%" }}
                className="hover-image"
              />
            </Grid>
          </a>
        </Grid>

        {/* Third Row */}
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6, lg: 6 }}>
            <a
              href="https://callanywhere.co.in/"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                sx={{
                  width: "350px",
                  height: "80px",
                  background: "white",
                  borderRadius: "50px",
                }}
              >
                <a href="https://puzzlehouse.in/">
                  <img
                    src="Puzzle (500 x 200 px)_edited.jpg"
                    alt="logo"
                    className="hover-image"
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
              </Grid>
            </a>
          </Grid>

          {/* Contact Component Placeholder */}
          <Grid
            size={{ xs: 12, md: 6, lg: 6 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid sx={{ width: "200px", height: "200px" }}>
              <Contact />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
