import "./App.css";
import Frontpage from "./Components/Frontpage";

function App() {
  return (
    <div className="App">
      <Frontpage />
    </div>
  );
}

export default App;
